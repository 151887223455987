import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import {
  Button,
  Divider,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Add, Close, Edit, Assessment } from "@material-ui/icons";
import Api from "../../model/Api";
import { useHistory, useParams } from "react-router-dom";


const useStyles = makeStyles(taskStyles);

export default function LockerHistory() {

  const { lockerID } = useParams();
  const classes = useStyles();
  const [locker, setLocker] = useState({});

  function getLocker() {
    new Api()
      .get("/api/lockers/" + lockerID)
      .then((w) => w.json())
      .then((w) => setLocker(w));
  }
  useEffect(() => {
    getLocker();
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className="cardTitleWhite">
              <span>{locker.name} Locker History</span>
            </h4>
            <p className="cardCategoryWhite">Reserves</p>
          </CardHeader>
          <CardBody>
            {
              locker &&
              <Table
                tableHeaderColor="primary"
                tableHead={["User","Username","Date","Hour"]}
                filterCols={["user","username"]}
                tableData={[
                  ...(locker.reserves ?? []).map((w, index) => ({
                    user : w.user.name,
                    username : w.user.username,
                    date : w.date,
                    hour : w.hour,
                    // action: <>
                    //   <IconButton
                    //     aria-label="Close"
                    //     onClick={() => {
                    //       if (window.confirm("Are you sure???")) {
                    //         new Api()
                    //           .delete(`/api/lockers/${w.lockerID}`)
                    //           .then((w) => w.ok)
                    //           .then((w) => {
                    //             if (w == true) {
                    //               locker.splice(index, 1);
                    //               setLocker([...locker]);
                    //             }
                    //           });
                    //       }
                    //     }}
                    //     className={classes.tableActionButton}
                    //   >
                    //     <Close
                    //       className={
                    //         classes.tableActionButtonIcon + " " + classes.close
                    //       }
                    //     />
                    //   </IconButton>
                    // </>
                  }))
                ]}
              />}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
