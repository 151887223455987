import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import {
  Button,
  Divider,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Add, Close, Edit, Assessment } from "@material-ui/icons";
import Api from "../../model/Api";
import { useHistory } from "react-router-dom";
import { validate } from "helper/validator";


const useStyles = makeStyles(taskStyles);

export default function Lockers() {
  const classes = useStyles();
  const [newBox, setNewBox] = useState(false);
  const [form, setForm] = useState({ name: "" });
  const [lockers, setLockers] = useState([]);
  const [editMode, setEditMode] = useState(false);

  const [valid, setValid] = useState({});
  const schema = {
    name: { isRequired: true }
  };

  const history = useHistory();

  function getLockers() {
    new Api()
      .get("/api/lockers")
      .then((w) => w.json())
      .then((w) => setLockers(w));
  }
  useEffect(() => {
    getLockers();
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className="cardTitleWhite">
              <span>Lockers</span>
              <Button
                className="sm-button"
                size="small"
                area-aria-label="add"
                variant="outlined"
                onClick={() => {
                  const state = !newBox;
                  setNewBox(state);
                  if (state) {
                    setEditMode(false);
                    setForm({});
                  }
                }}
              >
                <Add /> New
              </Button>{" "}
            </h4>
            <p className="cardCategoryWhite">manages lockers</p>
          </CardHeader>
          <CardBody>
            {newBox && (
              <>
                <GridContainer>
                  <GridItem md={3}>
                    <TextField
                      value={form.name}
                      onChange={(w) =>
                        setForm({ ...form, name: w.target.value })
                      }
                      label="title"
                      fullWidth
                    />
                    <label className="val-error">
                      {valid.name}
                    </label>
                  </GridItem>
                  <GridItem md={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        const res = validate({ model: schema, formData: form });
                        setValid(res);
                        if (res.isValid == true) {
                          if (editMode) {
                            new Api()
                              .put(`/api/lockers/${form.lockerID}`, {
                                method: "put",
                                body: JSON.stringify({
                                  ...form,
                                }),
                              })
                              .then((w) => w.json())
                              .then((w) => {
                                if (w == true) {
                                  getLockers();
                                  setEditMode(false);
                                  setForm({});
                                  setNewBox(false);
                                }
                              });
                          }
                          else {
                            new Api()
                              .post("/api/lockers", {
                                method: "post",
                                body: JSON.stringify({
                                  ...form,
                                }),
                              })
                              .then((w) => w.json())
                              .then((w) => {
                                if (w.lockerID) {
                                  setLockers([...lockers, form]);
                                  setForm({ name: "" });
                                  setNewBox(false);
                                }
                              });
                          }
                        }
                      }}
                      style={{ marginTop: 6 }}
                    >
                      Save
                    </Button>
                  </GridItem>
                </GridContainer>
                <br />
                <Divider />
              </>
            )}
            <Table
              tableHeaderColor="primary"
              tableHead={["Name","Reserves", "#"]}
              filterCols={["name"]}
              tableData={[
                ...lockers.map((w, index) => ({
                  name: w.name,
                  reserves : <div className="reserved-locker-time">{(w.reserves ?? []).map((c) => (
                    <span>{c.hour}</span>
                  ))}</div>,
                  action: <>
                   <Tooltip
                      title="reserve history"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                    <IconButton onClick={() => {
                      history.push(`/admin/lockers/${w.lockerID}`);
                    }}
                      aria-label="Edit"
                      className={classes.tableActionButton}
                    >
                      <Assessment
                        className={
                          classes.tableActionButtonIcon + " " + classes.edit
                        }
                      />
                    </IconButton>
                    </Tooltip>
                    <IconButton onClick={() => {
                      new Api().get(`/api/lockers/${w.lockerID}`).then(a => a.json()).then(a => {
                        setForm(a);
                        setNewBox(true);
                        setEditMode(true);
                      })
                    }}
                      aria-label="Edit"
                      className={classes.tableActionButton}
                    >
                      <Edit
                        className={
                          classes.tableActionButtonIcon + " " + classes.edit
                        }
                      />
                    </IconButton>

                    <IconButton
                      aria-label="Close"
                      onClick={() => {
                        if (window.confirm("Are you sure???")) {
                          new Api()
                            .delete(`/api/lockers/${w.lockerID}`)
                            .then((w) => w.ok)
                            .then((w) => {
                              if (w == true) {
                                lockers.splice(index, 1);
                                setLockers([...lockers]);
                              }
                            });
                        }
                      }}
                      className={classes.tableActionButton}
                    >
                      <Close
                        className={
                          classes.tableActionButtonIcon + " " + classes.close
                        }
                      />
                    </IconButton>
                  </>
                }))
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
