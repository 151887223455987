import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import avatar from "assets/img/faces/marc.jpg";
import { Avatar, Divider, FormControl, FormControlLabel, MenuItem, Select, TextField, Tooltip } from "@material-ui/core";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CheckBox } from "@material-ui/icons";
import TimeCalendar from "react-timecalendar";
import marc from '../../assets/img/faces/marc.jpg';
import Api from "../../model/Api";
import { useHistory } from "react-router";
import { validate } from "helper/validator";

const styles = {
  formControl: {
    margin: 1,
    width: "100%",
  },
  selectEmpty: {
    marginTop: 2,
  },
  avatar: {
    margin: 'auto',
    display: 'block',
    width: '41%',
    maxWidth: 150,
    borderRadius: '50%',
    boxShadow: '1px 1px 14px -3px #333',
    cursor: 'pointer'
  }
};

const useStyles = makeStyles(styles);

export default function CreateUser() {
  const classes = useStyles();

  const [form, setForm] = useState({ username: "" });
  const [userImage, setUserImage] = useState();
  const history = useHistory();
  const [valid, setValid] = useState({});
  const schema = {
    name: { isRequired: true },
    email: { isRequired: true },
    username: { isRequired: true },
    password: { isRequired: true }
  };
  return (
    <div>
      <GridContainer>
        <GridItem md={12} sm={12} xs={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className="cardTitleWhite">Create User</h4>
              <p className="cardCategoryWhite">Create new user</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem md={12}>
                  <input accept="images/*"
                    type="file" id="user-avatar" onChange={(e) => {
                      setForm({ file: e.target.files[0], ...form });
                      setUserImage(URL.createObjectURL(e.target.files[0]));
                    }} hidden />
                  <label for="user-avatar">
                    <img className={classes.avatar} alt="Remy Sharp" src={userImage ?? marc} />
                  </label>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <TextField
                    label="Name"
                    id="name"
                    value={form.name}
                    onChange={(e) => {
                      setForm({ ...form, name: e.target.value });
                    }}
                    fullWidth


                  />
                  <label className="val-error">
                    {valid.name}
                  </label>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <TextField
                    label="Username"
                    id="username"
                    value={form.username}
                    onChange={(e) => {
                      console.log("ewqwe", form);
                      setForm({ ...form, username: e.target.value });
                    }}
                    fullWidth

                  />
                  <label className="val-error">
                    {valid.username}
                  </label>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <TextField
                    label="Phone"
                    id="phone"
                    value={form.phone}
                    onChange={(e) => {
                      setForm({ ...form, phone: e.target.value });
                    }}
                    fullWidth

                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <TextField
                    label="Email"
                    id="email"
                    value={form.email}
                    onChange={(e) => {
                      setForm({ ...form, email: e.target.value });
                    }}
                    fullWidth

                  />
                  <label className="val-error">
                    {valid.email}
                  </label>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <TextField
                    label="Birthday"
                    id="birthday"
                    value={form.birthday}
                    onChange={(e) => {
                      setForm({ ...form, birthday: e.target.value });
                    }}
                    fullWidth

                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                  <TextField
                    label="Password"
                    id="password"
                    type="password"
                    value={form.password}
                    onChange={(e) => {
                      setForm({ ...form, password: e.target.value });
                    }}
                    fullWidth

                  />
                  <label className="val-error">
                    {valid.password}
                  </label>
                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                  <TextField
                    label="Address"
                    id="address"
                    value={form.address}
                    onChange={(e) => {
                      setForm({ ...form, address: e.target.value });
                    }}
                    fullWidth

                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={3}>
                  <TextField
                    label="Zip Code"
                    id="zipcode"
                    value={form.zipcode}
                    onChange={(e) => {
                      setForm({ ...form, zipcCode: e.target.value });
                    }}
                    fullWidth
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Gender</InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={form.gender}
                      onChange={(e) => {
                        setForm({ ...form, gender: e.target.value });
                      }}
                    //value={age}
                    // onChange={handleChange}
                    >
                      <MenuItem value={true}>male</MenuItem>
                      <MenuItem value={false}>famle</MenuItem>

                    </Select>
                  </FormControl>
                </GridItem>


                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    label="Emergency Phone"
                    id="emergencyNumber"
                    value={form.emergencyNumber}
                    onChange={(e) => {
                      setForm({ ...form, emergencyNumber: e.target.value });
                    }}
                    fullWidth
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    label="Emergency Contact"
                    id="emergencyContact"
                    value={form.emergencyContact}
                    onChange={(e) => {
                      setForm({ ...form, emergencyContact: e.target.value });
                    }}
                    fullWidth
                  />
                </GridItem>

              </GridContainer>

            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={() => {
                const res = validate({ model: schema, formData: form });
                setValid(res);
                if (res.isValid == true) {
                  form.registerDate = new Date();
                  new Api().postWithFile('/api/users', form).then(a => a.json()).then(a => {
                    console.log("x", a);
                    if (a.userID) {
                      history.goBack();
                    }
                  });
                }
              }}>Save</Button>
            </CardFooter>
          </Card>

        </GridItem>

      </GridContainer>

    </div>
  );
}
