import decode from "jwt-decode";
import axios from "axios";
// import * as signalR from "@aspnet/signalr";
import { tokenName } from './AuthService'
export default class Api {
  constructor() {
    this.get.bind(this);
    this.post.bind(this);
    this.put.bind(this);
    this.delete.bind(this);
    this.getUserId.bind(this);
    this.postWithFile.bind(this);
    this.putWithFile.bind(this);
    this.postAxios.bind(this);
  }

  _getToken = () => {
    // Retrieves the user token from localStorage
    return localStorage.getItem(tokenName);
  };

  getUserId() {
    const decoded = decode(this._getToken());
    return decoded[
      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
    ];
  }
  postAxios(url, data, config) {
    axios.post(url, data, config);
  }

  downloadFile(url, options) {
    options = {
      ...options,
      headers: {
        Authorization: "Bearer " + this._getToken(),
      },
    };
    fetch(url, options).then(a => a.blob()).then((a) => {
      const file = window.URL.createObjectURL(a);
      window.location.assign(file);
    });
  }

  get(url, options) {
    options = {
      ...options,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this._getToken(),
      },
    };
    return fetch(url, options);
  }
  post(url, options) {
    options = {
      ...options,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this._getToken(),
      },
    };
    return fetch(url, options);
  }
  postWithFile(url, body) {
    var f = new FormData();
    var obj = {};
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        if (body[key] instanceof FileList) {
          for (let index = 0; index < body[key].length; index++) {
            f.append(key, body[key][index]);
          }
        } else {
          if (key == 'file') {
            f.append(key, body[key]);
          }
          // check is file
          // else if(body[key].name && body[key].size) {
          //   f.append(key, body[key]);
          // }
          else {

            if (body[key] != null) {
              obj[key] = body[key];
            }
          }
        }
      }
    }
    delete body['file'];
    f.append("data", JSON.stringify(obj));


    let options = {
      body: f,
      method: "post",
      headers: {
        Authorization: "Bearer " + this._getToken(),
      },
    };
    return fetch(url, options);
  }
  putWithFile(url, body) {
    var f = new FormData();
    var obj = {};
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        if (body[key] instanceof FileList) {

          for (let index = 0; index < body[key].length; index++) {
            f.append(key, body[key][index]);
          }
        } else {
          if (key == 'file') {
            f.append(key, body[key]);
          }
          // check is file
          // else if(body[key].name && body[key].size) {
          //   f.append(key, body[key]);
          // }
          else {

            if (body[key] != null) {
              obj[key] = body[key];
            }
          }
        }
      }
    }

    f.append("data", JSON.stringify(obj));

    let options = {
      body: f,
      method: "put",
      headers: {
        Authorization: "Bearer " + this._getToken(),
      },
    };
    return fetch(url, options);
  }
  put(url, options) {
    options = {
      ...options,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this._getToken(),
      },
    };
    return fetch(url, options);
  }
  delete(url, options) {
    options = {
      ...options,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this._getToken(),
      },
    };
    return fetch(url, options);
  }
}
