function getOffedPrice(product) {
    console.log("ww",product);
    if(product.priceOff) {
        product.price -= product.priceOff;
    }
    else  if(product.percentOff) {
        product.price -=  product.price * (product.percentOff / 100);
    }
    return product.price;
}

module.exports = {
    getOffedPrice
}