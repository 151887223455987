import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle.js";

import {
  Button,
  Divider,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Add, Close, Edit, Assessment } from "@material-ui/icons";
import Api from "../../model/Api";
import { validate } from "helper/validator";

const useStyles = makeStyles(taskStyles);

export default function Categories() {
  const classes = useStyles();
  const [newBox, setNewBox] = useState(false);
  const [form, setForm] = useState({ name: "" });
  const [categories, setCategories] = useState([]);
  const [editMode, setEditMode] = useState(false);


  const [valid, setValid] = useState({});
  const schema = {
    name: { isRequired: true },
  };

  function getCategories() {
    new Api()
      .get("/api/categories")
      .then((w) => w.json())
      .then((w) => setCategories(w));
  }

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className="cardTitleWhite">
              <span>Shop Categorie</span>
              <div>
                <Button
                  className="sm-button"
                  size="small"
                  area-aria-label="add"
                  variant="outlined"
                  onClick={() => {
                    const state = !newBox;
                    setNewBox(state);
                    if (state) {
                      setEditMode(false);
                      setForm({});
                    }
                  }}
                >
                  <Add /> New
                </Button>{" "}
                <Button className="sm-button"
                  size="small"
                  area-aria-label="ass"
                  variant="outlined" onClick={() => {

                    new Api().downloadFile('/api/excel/categories');
                  }}><Assessment /></Button>
              </div>
            </h4>
            <p className="cardCategoryWhite">manages categories</p>
          </CardHeader>
          <CardBody>
            {newBox && (
              <>
                <GridContainer>
                  <GridItem md={3}>
                    <TextField
                      value={form.name}
                      onChange={(w) =>
                        setForm({ ...form, name: w.target.value })
                      }
                      label="title"
                      fullWidth
                    />
                    <label className="val-error">
                      {valid.name}
                    </label>
                  </GridItem>
                  <GridItem md={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        const res = validate({ model: schema, formData: form });
                        setValid(res);
                        if (res.isValid == true) {

                          if (editMode) {
                            new Api()
                              .put(`/api/categories/${form.categoryID}`, {
                                method: "put",
                                body: JSON.stringify({
                                  ...form,
                                }),
                              })
                              .then((w) => w.json())
                              .then((w) => {
                                console.log("edit", w);
                                if (w == true) {
                                  getCategories();
                                  setEditMode(false);
                                  setForm({});
                                  setNewBox(false);
                                }

                                // if (w.categoryID) {
                                //   setCategories([...categories, form]);
                                //   setForm({ title: "" });
                                //   setNewBox(false);
                                // }
                              });
                          }
                          else {
                            new Api()
                              .post("/api/categories", {
                                method: "post",
                                body: JSON.stringify({
                                  ...form,
                                }),
                              })
                              .then((w) => w.json())
                              .then((w) => {
                                if (w.categoryID) {
                                  setCategories([...categories, form]);
                                  setForm({ title: "" });
                                  setNewBox(false);
                                }
                              });
                          }
                        }
                      }}
                      style={{ marginTop: 6 }}
                    >
                      Save
                    </Button>
                  </GridItem>
                </GridContainer>
                <br />
                <Divider />
              </>
            )}
            <Table
              tableHeaderColor="primary"
              tableHead={["Name", "#"]}
              filterCols={["name"]}
              tableData={[
                ...categories.map((w, index) => ({
                  name: w.name,
                  action: <>
                    <IconButton onClick={() => {
                      new Api().get(`/api/categories/${w.categoryID}`).then(a => a.json()).then(a => {
                        setForm(a);
                        setNewBox(true);
                        setEditMode(true);
                      })
                    }}
                      aria-label="Edit"
                      className={classes.tableActionButton}
                    >
                      <Edit
                        className={
                          classes.tableActionButtonIcon + " " + classes.edit
                        }
                      />
                    </IconButton>

                    <IconButton
                      aria-label="Close"
                      onClick={() => {
                        if (window.confirm("Are you sure???")) {
                          new Api()
                            .delete(`/api/categories/${w.categoryID}`)
                            .then((w) => w.ok)
                            .then((w) => {
                              if (w == true) {
                                categories.splice(index, 1);
                                setCategories([...categories]);
                              }
                            });
                        }
                      }}
                      className={classes.tableActionButton}
                    >
                      <Close
                        className={
                          classes.tableActionButtonIcon + " " + classes.close
                        }
                      />
                    </IconButton>
                  </>,
                }))
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
