import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {
  Button,
  IconButton,
} from "@material-ui/core";
import { Add, Category, Close, Edit, Assessment } from "@material-ui/icons";
import { useHistory } from "react-router";
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import Api from "../../model/Api";
import { Link } from "react-router-dom";

const useStyles = makeStyles(taskStyles);

export default function ProductList() {
  const [products, setProducts] = useState([]);
  const classes = useStyles(taskStyles);
  const history = useHistory();

  useEffect(() => {
    new Api()
      .get("/api/products")
      .then((w) => w.json())
      .then((w) => {
        setProducts(w);
      });
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className="cardTitleWhite">
              {" "}
              <span>Products</span>{" "}
              <div>

                <Button
                  className="sm-button"
                  size="small"
                  area-aria-label="add"
                  variant="outlined"
                  onClick={() => {
                    history.push("/admin/products/create");
                  }}
                >
                  <Add /> New
                </Button>{" "}

                <Button className="sm-button"
                  size="small"
                  area-aria-label="ass"
                  variant="outlined" onClick={() => { 

                    new Api().downloadFile('/api/excel/products');
                  }}><Assessment /></Button>
              </div>
            </h4>
            <p className="cardCategoryWhite">manages products</p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["Title", "category", "price", "#"]}
              filterCols={["title", "category"]}
              tableData={[
                ...products.map((w, index) => ({
                  title: w.title ?? "",
                  category: w.category?.name ?? "",
                  price: w.price,
                  action: <>
                    <IconButton
                      aria-label="Edit"
                      className={classes.tableActionButton}
                      onClick={() =>
                        history.push(`/admin/products/edit/${w.productID}`)
                      }
                    >
                      <Edit
                        className={
                          classes.tableActionButtonIcon + " " + classes.edit
                        }
                      />
                    </IconButton>

                    <IconButton
                      onClick={() => {
                        if (window.confirm("Are you sure???")) {
                          new Api()
                            .delete(`/api/products/${w.productID}`)
                            .then((w) => w.ok)
                            .then((w) => {
                              if (w) {
                                products.splice(index, 1);
                                setProducts([...products]);
                              }
                            });
                        }
                      }}
                      aria-label="Close"
                      className={classes.tableActionButton}
                    >
                      <Close
                        className={
                          classes.tableActionButtonIcon + " " + classes.close
                        }
                      />
                    </IconButton>
                  </>
                }))
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
