import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { TableFooter } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles(styles);

export default function CustomTable({ tableHead, tableData, tableHeaderColor, filterCols, pageSize = 8 }) {
  const classes = useStyles();
  //filter sample : ["Field1","Filed2","Filed3={Target Filed to use as Filter}"] , ["User=Name"] => Filter User Column buy filter value from Name column
  const [filter, setFilter] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState();
  const [page, setPage] = useState(1);


  useEffect(() => {
    let w = tableData;
    Object.entries(filter).forEach(a => {
      const [key, value] = a;

      if (value) {
        // key is filtered via another filed 
        // like User=Name  filter user column by Name column data
        if (key.split('=')[1]) {
          const filed = key.split('=')[1];
          w = w.filter(a => a[filed]?.toString().toLowerCase().includes(value.toLowerCase()));
        }
        else {
          w = w.filter(a => a[key]?.toString().toLowerCase().includes(value.toLowerCase()));
        }
      }
    });


    setData(w);
  }, [refresh, tableData,page]);

  useEffect(() => {
    let x = {};
    if (filterCols) {
      filterCols.forEach(a => {
        x[a] = null;
      });
      setFilter(x);
    }
  }, []);

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {
                      (filterCols ?? []).some(a => a.toLowerCase() == prop.toLowerCase() || (a.split('=')[0] && a.split('=')[0].toLowerCase() == prop.toLowerCase())) ?
                        <div className="filter-input">
                          <input type="text" onChange={(e) => {
                            let x = filter;
                            x[filterCols.find(a => a.toLowerCase() == prop.toLowerCase() || (a.split('=')[0] && a.split('=')[0].toLowerCase() == prop.toLowerCase())) ?? prop] = e.target.value;
                            setFilter({ ...x });
                            setRefresh(!refresh);
                          }} placeholder={prop} value={filter[prop]} />
                          <i className="fa fa-search" ></i>
                        </div> :
                        <span>{prop}</span>
                    }
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {
            (data ?? tableData).length == 0 ?
              <TableRow>
                <TableCell colSpan={tableHead.length}>
                  <div className="no-row">
                    There is no row
                  </div>
                </TableCell>
              </TableRow> :
              (data ?? tableData).slice((page - 1) * pageSize, ((page  - 1) * pageSize) + pageSize).map((prop, key) => {
                return (
                  <TableRow key={key} className={classes.tableBodyRow}>
                    {Object.entries(prop).map((p, k) => {
                      const [_k, _p] = p;
                      if (tableHead.some(c => c.toLowerCase() == _k.toLowerCase()) || _k == 'action') {
                        return (
                          <TableCell className={classes.tableCell} key={k}>
                            {_p}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
        </TableBody>
        <TableFooter>
          <div style={{padding : 15}}>
            <Pagination page={page} count={Math.ceil((data ?? tableData).length / pageSize)} size="small" variant="outlined" onChange={(_,index) => {
              setPage(index);
            }} />
          </div>
        </TableFooter>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray"
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};
