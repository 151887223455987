import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {
  Button,
  IconButton,
} from "@material-ui/core";
import { Add, Close, Edit, ChatBubble,Assessment, DateRange } from "@material-ui/icons";
import { useHistory } from "react-router";
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import Api from "../../model/Api";


const useStyles = makeStyles(taskStyles);

export default function DoctorList() {
  const [doctors, setDoctors] = useState([]);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    new Api()
      .get("/api/doctors")
      .then((w) => w.json())
      .then((w) => setDoctors(w));
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className="cardTitleWhite">
              {" "}
              <span>Doctors</span>{" "}
              <div>
              <Button
                className="sm-button"
                size="small"
                area-aria-label="add"
                variant="outlined"
                onClick={() => {
                  history.push("/admin/doctors/create");
                }}
              >
                <Add /> New
              </Button>{" "}
              <Button className="sm-button"
                  size="small"
                  area-aria-label="ass"
                  variant="outlined" onClick={() => { 
                    new Api().downloadFile('/api/excel/doctors');
                  }}><Assessment /></Button>
                  </div>
            </h4>
            <p className="cardCategoryWhite">manages doctors</p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["Name", "Username", "Phone", "Email","Field", "#"]}
              filterCols={["name","field","username","email","phone"]}
              tableData={[
                ...doctors.map((w, index) => ({
                  name: w.name ?? "",
                  username: w.username,
                  phone: w.phone,
                  email: w.email,
                  field: w.field,
                  action: <>
                   <IconButton
                      aria-label="Edit"
                      className={classes.tableActionButton}
                      onClick={() =>
                        history.push(`/admin/doctors/edit/${w.doctorID}/time`)
                      }
                    >
                      <DateRange
                        className={
                          classes.tableActionButtonIcon + " " + classes.edit
                        }
                      />
                    </IconButton>
                    <IconButton
                      aria-label="Edit"
                      className={classes.tableActionButton}
                      onClick={() =>
                        history.push(`/admin/doctors/edit/${w.doctorID}`)
                      }
                    >
                      <Edit
                        className={
                          classes.tableActionButtonIcon + " " + classes.edit
                        }
                      />
                    </IconButton>

                    <IconButton
                      onClick={() => {
                        history.push(`/admin/doctors/tickets/${w.doctorID}`)
                      }}
                      aria-label="chat"
                      className={classes.tableActionButton}
                    >
                      <ChatBubble
                        className={
                          classes.tableActionButtonIcon + " " + classes.edit
                        }
                      />
                    </IconButton>


                    <IconButton
                      onClick={() => {
                        if (window.confirm("Are you sure???")) {
                          new Api()
                            .delete(`/api/doctors/${w.doctorID}`)
                            .then((w) => w.ok)
                            .then((w) => {
                              if (w) {
                                doctors.splice(index, 1);
                                setDoctors([...doctors]);
                              }
                            });
                        }
                      }}
                      aria-label="Close"
                      className={classes.tableActionButton}
                    >
                      <Close
                        className={
                          classes.tableActionButtonIcon + " " + classes.close
                        }
                      />
                    </IconButton>
                  </>
                }))

              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
