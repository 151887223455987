import React, { createContext, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import AuthService, { tokenName } from '../model/AuthService';

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }

    const decoded = jwtDecode(serviceToken);
    return decoded.exp > (Date.now() / 1000);
};


const JWTContext = createContext(
    {
        isLoggedIn: false,
        isInitialized: false,
        user: null
    }
);

export const JWTProvider = ({ children }) => {

    const [state, setState] = useState({
        isLoggedIn: true,
        isInitialized: false,
        user: null
    });

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem(tokenName);
                if (serviceToken && verifyToken(serviceToken)) {
                    const user = new AuthService().getProfile();
                    if (user.username) {
                        setState({ isLoggedIn: true, isInitialized: true, user });
                    }
                    else {
                        setState({ isLoggedIn: false, isInitialized: true, user: null });
                    }
                }
                else {
                    setState({ isLoggedIn: false, isInitialized: true, user: null });
                }
            } catch (err) {
                setState({ isLoggedIn: false, isInitialized: true, user: null });
                console.error(err);
            }
        };

        init();
    }, []);

    // if (!state.isInitialized) {
    //     return <h3>Loading ...</h3>;
    // }


    return (
        <JWTContext.Provider value={{ ...state }}>
                    { children } 
        </JWTContext.Provider>
    );
};

export default JWTContext;