
import React, { lazy } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import Admin from 'layouts/Admin'
import "assets/css/material-dashboard-react.css?v=1.9.0";
import { JWTProvider } from "context/JWTContext";
import SigninScreen from "views/Signin/SignIn";


const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <JWTProvider>
      <Switch>
        <Route path="/signin" exact component={SigninScreen} />
        <Route path="/admin" component={Admin} />
        <Route path="/">
          <Redirect to="/admin/dashboard" />
        </Route>
      </Switch>
    </JWTProvider>
  </Router>,
  document.getElementById("root")
);
