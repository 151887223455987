import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {
  Button,
  IconButton, FormControl, FormControlLabel, MenuItem, Select
} from "@material-ui/core";
import { Add, Close, Edit, ChatBubble,Assessment } from "@material-ui/icons";
import { useHistory } from "react-router";
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import Api from "../../model/Api";
import { getOffedPrice } from "helper/helper";


const useStyles = makeStyles(taskStyles);

export default function OrderList() {
  const [orders, setOrders] = useState([]);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    new Api()
      .get("/api/orders")
      .then((w) => w.json())
      .then((w) => setOrders(w));
  }, []);

  console.log("cc",orders);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className="cardTitleWhite">
              <span>Orders</span>{" "}
              <div>
                <Button
                className="sm-button"
                size="small"
                area-aria-label="add"
                variant="outlined"
                onClick={() => {
                  history.push("/admin/orders/create");
                }}
              >
                <Add /> New
              </Button>
              <Button className="sm-button"
                  size="small"
                  area-aria-label="ass"
                  variant="outlined" onClick={() => { 

                    new Api().downloadFile('/api/excel/orders');
                  }}><Assessment /></Button>
                  </div>
            </h4>
            <p className="cardCategoryWhite">manages orders</p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["User", "Date", "Description", "Cart", "State","PaymentType", "#"]}
              filterCols={["user=name","paymentType"]}
              tableData={[
                ...orders.map((w, index) => ({
                  user:  <div className="user-cell">
                     ({w.userID}) {w?.user?.name}<span>
                    <a href={`tel:${w?.user?.phone}`}><i className="fa fa-phone"></i> {w?.user?.phone}</a>
                    @{w?.user?.username}
                    </span>
                     </div>,
                     name : w.user.name,
                  date: <span>{new Date(w.date).toLocaleString('en')} </span>,
                  description: w.description ? <a href="javascript:;" onClick={() => {
                    alert(w.description ?? '');
                  }}>view description</a> : "no description",
                  cart: (w.orderdetails ?? []).length == 0 ? <span>cart is empty</span> : <table className="order-cart">
                    <tr>
                      <th>product</th>
                      <th>price</th>
                      <th>count</th>
                      <th>total</th>
                    </tr> {
                      (w.orderdetails ?? []).map(a => {
                        return (
                          <tr>
                            <td>{a?.product?.title}</td>
                            <td>{getOffedPrice(a?.product).toFixed(2)}$</td>
                            <td>{a?.count}</td>
                            <td>{(getOffedPrice(a?.product) * a?.count).toFixed(2) ?? 0}$</td>
                          </tr>
                        )
                      })
                    }
                  </table>,
                  state: <div style={{ width: 130 }}>
                    <FormControl className={classes.formControl}>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={w.state}
                        onChange={(e) => {
                          setOrders((r) => {
                            new Api().put('/api/orders/' + w.orderID, { body: JSON.stringify({ ...w, state: e.target.value }) });
                            r.find(a => a.orderID == w.orderID).state = e.target.value;
                            return [...r];
                          })
                        }}
                      >
                        <MenuItem value={"unsent"}>UnSent</MenuItem>
                        <MenuItem value={"sent"}>Sent</MenuItem>
                      </Select>
                    </FormControl>
                  </div>,
                  paymentType : w.paymentType ?? ' ',
                  action: <>
                    {/* <IconButton
                      aria-label="Edit"
                      className={classes.tableActionButton}
                      onClick={() =>
                        history.push(`/admin/doctors/edit/${w.orderID}`)
                      }
                    >
                      <Edit
                        className={
                          classes.tableActionButtonIcon + " " + classes.edit
                        }
                      />
                    </IconButton> */}

                    <IconButton
                      onClick={() => {
                        if (window.confirm("Are you sure to remove order ?")) {
                          new Api()
                            .delete(`/api/orders/${w.orderID}`)
                            .then((w) => w.ok)
                            .then((w) => {
                              if (w) {
                                orders.splice(index, 1);
                                setOrders([...orders]);
                              }
                            });
                        }
                      }}
                      aria-label="Close"
                      className={classes.tableActionButton}
                    >
                      <Close
                        className={
                          classes.tableActionButtonIcon + " " + classes.close
                        }
                      />
                    </IconButton>
                  </>
                }))

              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
