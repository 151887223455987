import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import {TextField } from "@material-ui/core";
import Api from "../../model/Api";
import { useHistory } from "react-router";


export default function Profile() {

  const [form, setForm] = useState({});
  const history = useHistory();

  function validate() {
    // if (form.username.length < 5) {
    //   setForm({ ...form, error: 'Username must have more than 5 characters' });
    //   return false;
    // }
    if (form.password.length < 4) {
      setForm({ ...form, error: 'Password length must have more than 4 characters' });
      return false;
    }
    if (form.password != form.rePassword) {
      setForm({ ...form, error: 'Passwords not match !' });
      return false;
    }
    return true;
  }

  useEffect(() => {
    new Api().get(`/api/users/profile`).then(a => a.json()).then(a => {
      setForm({ ...a, loaded: true });
    });
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem md={12} sm={12} xs={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className="cardTitleWhite">Profile</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {
                  form.loaded &&
                  <>

                    <GridItem xs={12} sm={6} md={4}>
                      <TextField
                        label="Username"
                        id="Username"
                        readonly
                        value={form.username}
                        // onChange={(e) => {
                        //   setForm({ ...form, username: e.target.value });
                        // }}
                        fullWidth


                      />
                    </GridItem>

                    <GridItem xs={12} sm={6} md={4}>
                      <TextField
                        label="Password"
                        id="password"
                        type="password"
                        value={form.password}
                        onChange={(e) => {
                          setForm({ ...form, password: e.target.value });
                        }}
                        fullWidth

                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                      <TextField
                        label="Confirm Password"
                        id="confirmPassword"
                        type="password"
                        value={form.rePassword}
                        onChange={(e) => {
                          setForm({ ...form, rePassword: e.target.value });
                        }}
                        fullWidth

                      />
                    </GridItem>
                  </>
                }
              </GridContainer>

            </CardBody>
            <CardFooter style={{ justifyContent: 'unset' }}>

              <Button color="primary" onClick={() => {
                if (validate()) {
                  delete form.error;
                  new Api().put(`/api/users/profile`, { body: JSON.stringify(form) }).then(a => a.json()).then(a => {
                    console.log("x", a);
                    if (a == true) {
                      history.goBack();
                    }
                  });
                }
              }}>Save</Button>
              {
                form.error &&
                <div style={{ color: 'red' }}>
                  {form.error}
                </div>
              }
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

    </div>
  );
}
