import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import selectImage from '../../assets/img/selectImage.jpg';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Api from "../../model/Api";
import { useHistory } from "react-router";

import { validate } from '../../helper/validator';

const styles = {
  formControl: {
    margin: 1,
    width: "100%",
    marginTop: 25,
  },
  selectEmpty: {
    marginTop: 2,
  },
};

const useStyles = makeStyles(styles);

export default function CreateBlog() {
  const classes = useStyles();
  const [form, setForm] = useState({ isActive: false, blogType: 'blog' });
  const [blogImage, setBlogImage] = useState();
  const history = useHistory();
  const [valid, setValid] = useState({});

  const schema = {
    title: { isRequired: true },
    writer: { isRequired: true }
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4 className="cardTitleWhite">Create blog</h4>
          <p className="cardCategoryWhite">{form.title}</p>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem md={4}>
              <label htmlFor="imageUpload">
                <img src={blogImage ?? selectImage} style={{ width: '100%' }} />
              </label>
              <input type="file" hidden id="imageUpload" onChange={(e) => {
                setForm({ ...form, file: e.target.files[0] });
                setBlogImage(URL.createObjectURL(e.target.files[0]));
              }} />
            </GridItem>
            <GridItem md={8}>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  fullWidth
                  label="title"
                  value={form.title} className={valid.title && 'val-error'}
                  onChange={(w) => setForm({ ...form, title: w.target.value })}
                />
                <label className="val-error">
                  {valid.title}
                </label>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  fullWidth
                  label="Short Description"
                  value={form.shortDescription}
                  onChange={(w) =>
                    setForm({ ...form, shortDescription: w.target.value })
                  }
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  fullWidth
                  label="Writer"
                  value={form.writer}
                  onChange={(w) => setForm({ ...form, writer: w.target.value })}
                />
                <label className="val-error">
                  {valid.writer}
                </label>
              </GridItem>

              <GridItem sm={12} xs={12} md={12}>
                <GridContainer>

                  <GridItem xs={12} sm={6} md={6}>
                    <FormControl className={classes.formControl}>
                      <InputLabel>blog type</InputLabel>
                      <Select
                        value={form.blogType}
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        //value={age}
                        onChange={(w) =>
                          setForm({ ...form, blogType: w.target.value })
                        }
                      >
                        <MenuItem value="blog">blog</MenuItem>
                        <MenuItem value="video">video</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>

                  <GridItem md={6} sm={6} xs={12}>
                    <FormControlLabel
                      style={{ marginTop: 45 }}
                      control={
                        <Checkbox
                          color="primary"
                          checked={form?.isActive ?? false}
                          onChange={(w) =>
                            setForm({ ...form, isActive: w.target.checked })
                          }
                        />
                      }
                      label=" active"
                    />
                  </GridItem>

                </GridContainer>


              </GridItem>
            </GridItem>

            {
              form.blogType == 'video' &&

              <>
                <GridItem xs={4} sm={4} md={2}>
                  <FormControl style={{ width: '100%' }}>
                    <InputLabel>Video type</InputLabel>
                    <Select
                      value={form.videoType}
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      //value={age}
                      onChange={(w) => {
                        setForm({ ...form, videoType: w.target.value });
                      }
                      }
                    >
                      <MenuItem value="youtube">youtube</MenuItem>
                      <MenuItem value="url">url</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={10}>
                  <TextField
                    fullWidth
                    label={`enter ${form.videoType == 'youtube' ? 'youtube video ID (ex : https://www.youtube.com/watch?v=[youtube_video_id])' : 'video url'}`}
                    value={form.videoUrl}
                    onChange={(w) => setForm({ ...form, videoUrl: w.target.value })}
                  />
                </GridItem>

              </>
            }
          </GridContainer>

          <br />

          <CKEditor
            config={{
              ckfinder: {
                uploadUrl: "/api/ckeditor",
              },
            }}
            data={form.description ?? ' '}
            editor={ClassicEditor}
            //data="xxxx"
            onInit={(editor) => {
              // console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setForm({ ...form, description: data });
            }}
            onBlur={(event, editor) => {
              // console.log("Blur.", editor);
            }}
            onFocus={(event, editor) => {
              // console.log("Focus.", editor);
            }}
          />

        </CardBody>
        <CardFooter>
          <Button
            onClick={() => {
              const res = validate({ model: schema, formData: form });
              setValid(res);
              if (res.isValid == true) {
                new Api()
                  .postWithFile("/api/blogs", form)
                  .then((w) => w.json())
                  .then((w) => {
                    if (w.blogID) {
                      history.goBack();
                    }
                  });
              }
            }}
            color="primary"
          >
            Save
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}
