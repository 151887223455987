import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle.js";

import {
  Button,
  Divider,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Add, Close, Edit } from "@material-ui/icons";
import Api from "../../model/Api";
import { validate } from "helper/validator";

const useStyles = makeStyles(taskStyles);

export default function SettingsPage() {
  const classes = useStyles();
  const [newBox, setNewBox] = useState(false);
  const [form, setForm] = useState({ name: "" });
  const [settings, setSettings] = useState([]);
  const [editMode, setEditMode] = useState(false);


  const [valid, setValid] = useState({});
  const schema = {
    value: { isRequired: true },
  };

  function getSettings() {
    new Api()
      .get("/api/settings")
      .then((w) => w.json())
      .then((w) => setSettings(w));
  }

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className="cardTitleWhite">
              <span>System Settings</span>

            </h4>
            <p className="cardCategoryWhite">manages categories</p>
          </CardHeader>
          <CardBody>
            {newBox && (
              <>
                <GridContainer>
                  <GridItem md={3}>
                    <TextField
                      value={form.name}
                      disabled
                      label="title"
                      fullWidth
                    />

                  </GridItem>
                  <GridItem md={3}>
                    <TextField
                      value={form.value}
                      onChange={(w) =>
                        setForm({ ...form, value: w.target.value })
                      }
                      label="Value"
                      fullWidth
                    />
                    <label className="val-error">
                      {valid.value}
                    </label>
                  </GridItem>
                  <GridItem md={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        const res = validate({ model: schema, formData: form });
                        setValid(res);
                        if (res.isValid == true) {

                          if (editMode) {
                            new Api()
                              .put(`/api/settings/${form.settingID}`, {
                                method: "put",
                                body: JSON.stringify({
                                  ...form,
                                }),
                              })
                              .then((w) => w.json())
                              .then((w) => {
                                if (w == true) {
                                  getSettings();
                                  setEditMode(false);
                                  setForm({});
                                  setNewBox(false);
                                }

                                // if (w.categoryID) {
                                //   setCategories([...categories, form]);
                                //   setForm({ title: "" });
                                //   setNewBox(false);
                                // }
                              });
                          }
                          else {
                            new Api()
                              .post("/api/categories", {
                                method: "post",
                                body: JSON.stringify({
                                  ...form,
                                }),
                              })
                              .then((w) => w.json())
                              .then((w) => {
                                if (w.categoryID) {
                                  setSettings([...settings, form]);
                                  setForm({ title: "" });
                                  setNewBox(false);
                                }
                              });
                          }
                        }
                      }}
                      style={{ marginTop: 6 }}
                    >
                      Save
                    </Button>
                  </GridItem>
                </GridContainer>
                <br />
                <Divider />
              </>
            )}
            <Table
              tableHeaderColor="primary"
              tableHead={["title","value", "#"]}
              filterCols={["title"]}
              tableData={[
                ...settings.map((w, index) => ({
                  title: w.title,
                  value: w.value,
                  action: <>
                    <IconButton onClick={() => {
                      new Api().get(`/api/settings/${w.settingID}`).then(a => a.json()).then(a => {
                        setForm(a);
                        setNewBox(true);
                        setEditMode(true);
                      })
                    }}
                      aria-label="Edit"
                      className={classes.tableActionButton}
                    >
                      <Edit
                        className={
                          classes.tableActionButtonIcon + " " + classes.edit
                        }
                      />
                    </IconButton>
                  </>,
                }))
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
