import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Link } from 'react-router-dom';
import {
  Button,
  IconButton, FormControl, FormControlLabel, MenuItem, Select
} from "@material-ui/core";
import { Add, Close, Edit, ChatBubble,Assessment } from "@material-ui/icons";
import { useHistory } from "react-router";
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import Api from "../../model/Api";


const useStyles = makeStyles(taskStyles);

export default function PaymentList() {
  const [payments, setPayments] = useState([]);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    new Api()
      .get("/api/payments")
      .then((w) => w.json())
      .then((w) => setPayments(w));
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className="cardTitleWhite">
              <span>System Payments</span>{" "}
              <Button className="sm-button"
                  size="small"
                  area-aria-label="ass"
                  variant="outlined" onClick={() => { 

                    new Api().downloadFile('/api/excel/payments');
                  }}><Assessment /></Button>
            </h4>
            <p className="cardCategoryWhite">manages payments</p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["User", "Amount", "Type", "Date", "Description", "Order"]}
              filterCols={["user=name"]}
              tableData={[
                ...payments.map((w, index) => ({
                  id : w.paymentID,
                  name : w?.user?.name,
                  user: <div className="user-cell">
                      ({w.userID}) {w?.user?.name}<span>
                        <a href={`tel:${w?.user?.phone}`}><i className="fa fa-phone"></i> {w?.user?.phone}</a>
                        @{w?.user?.username}
                      </span>
                    </div>,
                  amount: w?.inc > 0 ? <span className="inc-cell">+{w.inc}$</span> : <span className="dec-cell">-{w.dec}$</span>,
                  type: w.type,
                  date: <span>{new Date(w.date).toLocaleString('en')} </span>,
                  description: w.description ? <a href="javascript:;" onClick={() => {
                    alert(w.description ?? '');
                  }}>view description</a> : "no description",
                  order: w.orderID ? <Link to="#"> Order {w.orderID}</Link> : "--"
                }))
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
