import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { FormControl, MenuItem, Select, TextField, } from "@material-ui/core";
import TimeCalendar from "react-timecalendar";
import Api from "../../model/Api";
import { useHistory, useParams } from "react-router";
import { validate } from "helper/validator";
import marc from '../../assets/img/faces/marc.jpg';


const styles = {
  formControl: {
    margin: 1,
    width: "100%",
    marginTop: 25
  },
  selectEmpty: {
    marginTop: 2,
  },
  avatar: {
    margin: 'auto',
    display: 'block',
    width: '9vw',
    height: '9vw',
    borderRadius: '50%',
    boxShadow: '1px 1px 14px -3px #333',
    cursor: 'pointer'
  }
};

const useStyles = makeStyles(styles);

export default function EditDoctor() {
  const classes = useStyles();

  const [doctorImage, setDoctorImage] = useState();
  const [form, setForm] = useState({
    activeTimes: [], timingType: "weekly", activeTimeWeekly:
      [{ day: "monday", dayNumber: 1, times: [] },
      { day: "tuesday", dayNumber: 2, times: [] },
      { day: "wednesday", dayNumber: 3, times: [] },
      { day: "thursday", dayNumber: 4, times: [] },
      { day: "friday", dayNumber: 5, times: [] },
      { day: "saturday", dayNumber: 6, times: [] },
      { day: "sunday", dayNumber: 7, times: [] },]
  });

  const [selectedWeekDay, setSelectedWeekDay] = useState("monday");

  const history = useHistory();
  const { id, mode } = useParams();

  const [valid, setValid] = useState({});
  const schema = {
    name: { isRequired: true },
    username: { isRequired: true },
    password: { isRequired: true },
    email: { isRequired: true }
  };

  function handleTimeClick(time) {
    const selectedDay = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`;
    const selectedTime = time.toLocaleTimeString(); //time.getHours() + '-' + time.getMinutes();
    const startDatetime = selectedDay + ' ' + selectedTime;
    time.setMinutes(time.getMinutes() + 30);
    const endDatetime = selectedDay + ' ' + time.toLocaleTimeString();
    let newBooking = {
      id: 1,
      start_time: startDatetime,
      end_time: endDatetime
    };
    const index = form.activeTimes.findIndex((a) => a.start_time == startDatetime);
    if (index > -1) {
      form.activeTimes.splice(index, 1);
      setForm({ ...form });
    }
    else {
      form.activeTimes = [...form.activeTimes, newBooking];
      setForm({ ...form });
    }
  }

  function handleDateClick(date) {
    // console.log("date", date);
  }

  useEffect(() => {
    new Api().get(`/api/doctors/${id}`).then(a => a.json()).then(a => {
      if (!a.activeTimeWeekly) {
        a.activeTimeWeekly = form.activeTimeWeekly;
      }
      setForm(a);
    });
  }, []);

  function _saveData() {
    const res = validate({ model: schema, formData: form });
    setValid(res);
    if (res.isValid == true) {
      new Api().putWithFile(`/api/doctors/${form.doctorID}`, form).then(a => a.json()).then(a => {
        history.replace('/admin/doctors');
      });
    }
  }

  return (
    <div>
      <GridContainer>
        {
          mode != "time" &&
          <GridItem md={4} sm={12} xs={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className="cardTitleWhite">Edit Doctor</h4>
                <p className="cardCategoryWhite">Edit doctor</p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem md={12}>
                    <input accept="images/*"
                      type="file" id="doctor-avatar" onChange={(e) => {
                        setForm({ file: e.target.files[0], ...form });
                        setDoctorImage(URL.createObjectURL(e.target.files[0]));
                      }} hidden />
                    <label for="doctor-avatar">
                      <img className={classes.avatar} src={doctorImage ?? `/images/doctor/${form.doctorID}.jpg`} onError={(e) => e.target.src = marc} />
                    </label>

                  </GridItem>
                  {
                    form.doctorID &&
                    <>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          label="Name"
                          id="name"
                          fullWidth
                          value={form.name}
                          onChange={(e) => {
                            setForm({ ...form, name: e.target.value });
                          }}
                        />
                        <label className="val-error">
                          {valid.name}
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControl className={classes.formControl}>
                          <InputLabel>Time type</InputLabel>
                          <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={form.timingType ?? "weekly"}
                            onChange={(e) => {
                              setForm({ ...form, timingType: e.target.value });
                            }}
                          >
                            <MenuItem value={"daily"}>daily</MenuItem>
                            <MenuItem value={"weekly"}>weekly</MenuItem>

                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          label="Username"
                          id="username"
                          fullWidth
                          value={form.username}
                          onChange={(e) => {
                            setForm({ ...form, username: e.target.value });
                          }}

                        />
                        <label className="val-error">
                          {valid.username}
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          label="Password"
                          id="password"
                          type="password"
                          value={form.password}
                          fullWidth
                          onChange={(e) => {
                            setForm({ ...form, password: e.target.value });
                          }}

                        />
                        <label className="val-error">
                          {valid.password}
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          label="Email"
                          id="email"
                          value={form.email}
                          fullWidth
                          onChange={(e) => {
                            setForm({ ...form, email: e.target.value });
                          }}

                        />
                        <label className="val-error">
                          {valid.email}
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          label="Phone"
                          id="phone"
                          type="tel"
                          value={form.phone}
                          fullWidth
                          onChange={(e) => {
                            setForm({ ...form, phone: e.target.value });
                          }}

                        />
                        <label className="val-error">
                          {valid.phone}
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          label="Field"
                          id="field"
                          fullWidth
                          value={form.field}
                          onChange={(e) => {
                            setForm({ ...form, field: e.target.value });
                          }}

                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          label="Commission"
                          id="commission"
                          fullWidth
                          type="number"
                          value={form.commission ?? 0}
                          onChange={(e) => {
                            setForm({ ...form, commission: e.target.valueAsNumber });
                          }}
                        />

                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControl className={classes.formControl}>
                          <InputLabel>Type</InputLabel>
                          <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={form.type}
                            onChange={(e) => {
                              setForm({ ...form, type: e.target.value });
                            }}
                          //value={age}
                          // onChange={handleChange}
                          >
                            <MenuItem value="nutritionist">Nutritionist</MenuItem>
                            <MenuItem value="coach">Coach</MenuItem>

                          </Select>
                        </FormControl>
                      </GridItem>
                    </>
                  }
                </GridContainer>

              </CardBody>
              <CardFooter>
                <Button color="primary" onClick={_saveData}>Save</Button>
              </CardFooter>
            </Card>

          </GridItem>

        }
        <GridItem md={mode == "time" ? 12 : 8} sm={12} xs={12}>
          {
            (form.timingType ?? "weekly") == "weekly" ?
              <Card>
                <CardHeader color="primary">
                  <h4 className="cardTitleWhite" style={{ textAlign: 'center' }}>Active Weekly Times {mode == "time" && `of ${form.name}`}</h4>
                </CardHeader>
                <CardBody>
                  <div className="h-tab-bar">
                    {["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].map((item, index) => (
                      <div onClick={() => { setSelectedWeekDay(item) }} className={`h-tab ${item == selectedWeekDay ? 'active' : ''}`}>{item.toUpperCase()}</div>
                    ))}

                  </div>


                  <div className="h-hours">
                    {Array.from({ length: 49 }, (_, i) => i).map((item, index) => {
                      const hour = Math.floor(item / 2).toString();
                      const time = `${hour.length == 1 ? '0' + hour : hour}:${item % 2 == 0 ? '00' : '30'}`;
                      const selected = form.activeTimeWeekly.some(a => a.day == selectedWeekDay
                        && a.times.some(b => b == time));
                        if(index == 48) {
                          return <div style={{flex : '13%'}}></div>
                        }
                      return (<div onClick={() => {
                        if (selected) {
                          const _index = form.activeTimeWeekly.find(a => a.day == selectedWeekDay).times.indexOf(time);
                          form.activeTimeWeekly.find(a => a.day == selectedWeekDay).times.splice(_index, 1);
                        }
                        else {
                          form.activeTimeWeekly.find(a => a.day == selectedWeekDay).times.push(time);
                        }
                        setForm({ ...form });
                      }} className={`h-hour ${selected == true ? 'active' : ''}`}>
                        {time}
                      </div>);
                    })}
                  </div>
                </CardBody>
                {
                  mode == "time" &&
                  <CardFooter>
                    <Button color="default" onClick={_saveData}>Save</Button>
                  </CardFooter>
                }
              </Card> :

              <Card>
                <CardHeader color="primary">
                  <h4 className="cardTitleWhite" style={{ textAlign: 'center' }}>Active Times</h4>
                </CardHeader>
                <CardBody>
                  {
                    form.activeTimes &&

                    <TimeCalendar
                      disableHistory
                      bookings={form.activeTimes}
                      clickable
                      timeSlot={30}
                      openHours={[[6, 24]]}
                      onTimeClick={handleTimeClick}
                      onDateFunction={handleDateClick}

                    />
                  }
                </CardBody>
                {
                  mode == "time" &&
                  <CardFooter>
                    <Button color="default" onClick={_saveData}>Save</Button>
                  </CardFooter>
                }
              </Card>
          }
        </GridItem>
      </GridContainer>

    </div>
  );
}
