import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {
  Button,
  IconButton,
} from "@material-ui/core";
import { Add, Close, Edit } from "@material-ui/icons";
import { useHistory } from "react-router";
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import Api from "../../model/Api";

const useStyles = makeStyles(taskStyles);

export default function BlogList() {
  const [blogs, setBlogs] = useState([]);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    new Api()
      .get("/api/blogs")
      .then((w) => w.json())
      .then((w) => {
        setBlogs(w);
      });
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className="cardTitleWhite">
              {" "}
              <span>Blogs</span>{" "}
              <Button
                className="sm-button"
                size="small"
                area-aria-label="add"
                variant="outlined"
                onClick={() => {
                  history.push("/admin/blogs/create");
                }}
              >
                <Add /> New
              </Button>{" "}
            </h4>
            <p className="cardCategoryWhite">manages Blogs</p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={[
                "title",
                "date",
                "writer",
                "blog type",
                "#",
              ]}
              filterCols={["title","writer","blog type"]}
              tableData={
                [...blogs.map((w, index) => ({
                  title: w.title,
                  short: w.shortDescription,
                  date: new Date(w.date)?.toLocaleString('en'),
                  writer: w.writer,
                  "blog type": w.blogType,
                  action : <>
                  <IconButton
                    aria-label="Edit"
                    className={classes.tableActionButton}
                    onClick={() =>
                      history.push(`/admin/blogs/edit/${w.blogID}`)
                    }
                  >
                    <Edit
                      className={
                        classes.tableActionButtonIcon + " " + classes.edit
                      }
                    />
                  </IconButton>
    
                  <IconButton
                    onClick={() => {
                      if (window.confirm("Are you sure???" + w.blogID)) {
                        new Api()
                          .delete(`/api/blogs/${w.blogID}`)
                          .then((w) => w.ok)
                          .then((w) => {
                            if (w) {
                              blogs.splice(index, 1);
                              setBlogs([...blogs]);
                            }
                          });
                      }
                    }}
                    aria-label="Close"
                    className={classes.tableActionButton}
                  >
                    <Close
                      className={
                        classes.tableActionButtonIcon + " " + classes.close
                      }
                    />
                  </IconButton>
                </>
                }
                ))]}


            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
