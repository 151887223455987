import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle.js";

import {
  Button,
  Divider,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Add, Close, Edit } from "@material-ui/icons";
import Api from "../../model/Api";
import { validate } from "helper/validator";

const useStyles = makeStyles(taskStyles);

export default function Memberships() {
  const classes = useStyles();
  const [newBox, setNewBox] = useState(false);
  const [form, setForm] = useState({ name: "" });
  const [memberships, setMemberships] = useState([]);
  const [editMode, setEditMode] = useState(false);


  const [valid, setValid] = useState({});
  const schema = { 
    title: { isRequired: true },
    days: { isRequired: true },
    price: { isRequired: true },
   };

  function getMemberships() {
    new Api()
      .get("/api/memberships")
      .then((w) => w.json())
      .then((w) => setMemberships(w));
  }

  useEffect(() => {
    getMemberships();
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className="cardTitleWhite">
              <span>Memberships</span>
              <Button
                className="sm-button"
                size="small"
                area-aria-label="add"
                variant="outlined"
                onClick={() => {
                  const state = !newBox;
                  setNewBox(state);
                  if (state) {
                    setEditMode(false);
                    setForm({});
                  }
                }}
              >
                <Add /> New
              </Button>{" "}
            </h4>
            <p className="cardCategoryWhite">manages memberships</p>
          </CardHeader>
          <CardBody>
            {newBox && (
              <>
                <GridContainer>
                  <GridItem md={2}>
                    <TextField
                      value={form.title}
                      onChange={(w) =>
                        setForm({ ...form, title: w.target.value })
                      }
                      label="title"
                      fullWidth
                    />
                       <label className="val-error">
   {valid.title}
  </label>
                  </GridItem>
                  <GridItem md={2}>
                    <TextField
                    type="number"
                      value={form.days}
                      onChange={(w) =>
                        setForm({ ...form, days: w.target.valueAsNumber })
                      }
                      label="Days"
                      fullWidth
                    />
                       <label className="val-error">
   {valid.days}
  </label>
                  </GridItem>
                  <GridItem md={2}>
                    <TextField
                    type="number"
                      value={form.price}
                      onChange={(w) =>
                        setForm({ ...form, price: w.target.value })
                      }
                      label="Price"
                      fullWidth
                    />
                       <label className="val-error">
   {valid.price}
  </label>
                  </GridItem>
                  <GridItem md={2}>
                    <TextField
                      value={form.off ?? 0}
                      type="number"
                      onChange={(w) =>
                        setForm({ ...form, off: w.target.valueAsNumber })
                      }
                      label="Off (%)"
                      fullWidth
                    />

                  </GridItem>
                  <GridItem md={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        const res = validate({ model: schema, formData: form });
                        setValid(res);
                     if (res.isValid == true) {
                     
                        if (editMode) {
                          new Api()
                            .put(`/api/memberships/${form.membershipID}`, {
                              method: "put",
                              body: JSON.stringify({
                                ...form,
                              }),
                            })
                            .then((w) => w.json())
                            .then((w) => {
                              if (w == true) {
                                getMemberships();
                                setEditMode(false);
                                setForm({});
                                setNewBox(false);
                              }

                              // if (w.categoryID) {
                              //   setCategories([...categories, form]);
                              //   setForm({ title: "" });
                              //   setNewBox(false);
                              // }
                            });
                        }
                        else {
                          new Api()
                            .post("/api/memberships", {
                              method: "post",
                              body: JSON.stringify({
                                ...form,
                              }),
                            })
                            .then((w) => w.json())
                            .then((w) => {
                              if (w.membershipID) {
                                setMemberships([...memberships, form]);
                                setForm({ title: "" });
                                setNewBox(false);
                              }
                            });
                        }
                      }
                      }}
                      style={{ marginTop: 6 }}
                    >
                      Save
                    </Button>
                  </GridItem>
                </GridContainer>
                <br />
                <Divider />
              </>
            )}
            <Table
              tableHeaderColor="primary"
              tableHead={["Title","Days","Price","Off", "#"]}
              filterCols={["title"]}
              tableData={[
                ...memberships.map((w, index) => ({
                  title: w.title,
                  days: w.days,
                  price: w.price,
                  off: w.off,
                  action: <>
                    <IconButton onClick={() => {
                      new Api().get(`/api/memberships/${w.membershipID}`).then(a => a.json()).then(a => {
                        setForm(a);
                        setNewBox(true);
                        setEditMode(true);
                      })
                    }}
                      aria-label="Edit"
                      className={classes.tableActionButton}
                    >
                      <Edit
                        className={
                          classes.tableActionButtonIcon + " " + classes.edit
                        }
                      />
                    </IconButton>

                    <IconButton
                      aria-label="Close"
                      onClick={() => {
                        if (window.confirm("Are you sure???")) {
                          new Api()
                            .delete(`/api/memberships/${w.membershipID}`)
                            .then((w) => w.ok)
                            .then((w) => {
                              if (w == true) {
                                memberships.splice(index, 1);
                                setMemberships([...memberships]);
                              }
                            });
                        }
                      }}
                      className={classes.tableActionButton}
                    >
                      <Close
                        className={
                          classes.tableActionButtonIcon + " " + classes.close
                        }
                      />
                    </IconButton>
                  </>,
                }))
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
