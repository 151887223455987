import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Button, Divider, Icon, IconButton, TextField, Tooltip } from "@material-ui/core";
import { Add, Close, Edit, ChatBubble } from "@material-ui/icons";
import { useHistory } from "react-router";
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle.js";

const useStyles = makeStyles(taskStyles);

export default function TicketsList() {
    const classes = useStyles();
    
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className="cardTitleWhite"> <span>Tickets of {"$Current User"}</span>  </h4>
                        <p className="cardCategoryWhite">
                            manage tickets
            </p>
                    </CardHeader>
                    <CardBody>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["user", "title", "date", "#"]}
                            tableData={[
                                ["Tom kakai", "how can i do that", "2020-10-10 22:40",
                                    <>
                                        <IconButton
                                            aria-label="Close"
                                            className={classes.tableActionButton}
                                        >
                                            <ChatBubble
                                                className={
                                                    classes.tableActionButtonIcon + " " + classes.close
                                                }
                                            />
                                        </IconButton>
                                    </>
                                ],
                                ["Tom bax", "how can i do that", "2020-10-10 22:40",
                                    <>
                                        <IconButton
                                            aria-label="Close"
                                            className={classes.tableActionButton}
                                        >
                                            <ChatBubble
                                                className={
                                                    classes.tableActionButtonIcon + " " + classes.close
                                                }
                                            />
                                        </IconButton>
                                    </>
                                ],
                            ]}
                        />
                    </CardBody>
                </Card>
            </GridItem>

        </GridContainer>
    );
}
