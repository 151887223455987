import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {
  Button,
  IconButton,
  Tooltip
} from "@material-ui/core";
import { Add, Assessment, Close, Edit, CastConnected } from "@material-ui/icons";
import { useHistory } from "react-router";
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import Api from "../../model/Api";
import AuthService,{tokenName} from "../../model/AuthService";


const useStyles = makeStyles(taskStyles);

export default function UsersList() {
  const [users, setUsers] = useState([]);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    new Api()
      .get("/api/users")
      .then((w) => w.json())
      .then((w) => setUsers(w));
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className="cardTitleWhite">
              <span>Users</span>
              <div>
                <Button
                  className="sm-button"
                  size="small"
                  area-aria-label="add"
                  variant="outlined"
                  onClick={() => {
                    history.push("/admin/users/create");
                  }}
                >
                  <Add /> New
                </Button>
                <Button className="sm-button"
                  size="small"
                  area-aria-label="ass"
                  variant="outlined" onClick={() => {

                    new Api().downloadFile('/api/excel/users');
                  }}><Assessment /></Button>
              </div>
            </h4>
            <p className="cardCategoryWhite">manages users</p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["ID", "Name", "Username", "phone", "email", "Expire", "#"]}
              filterCols={["id", "name","username","email","phone"]}
              tableData={[
                ...users.map((w, index) => ({
                  id: w.userID,
                  name: w.name ?? "",
                  username: w.username ?? "",
                  phone: w.phone,
                  email: w.email,
                  expire: w.accountExpire ? (new Date(w.accountExpire))?.toLocaleString('en', {
                    minute: '2-digit',
                    hour: '2-digit',
                    hour12: false,
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit'
                  }) : ' ... ',
                  action: <>
                    <Tooltip
                      title="user panel"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton onClick={() => {
                        const url = `/front/login/=@lq=@${new AuthService().getToken()}=@${w.userID}`;
                        window.open(url, '_blank').focus();
                      }}
                        aria-label="remote"
                        className={classes.tableActionButton}
                      >
                        <CastConnected
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>


                    <Tooltip
                      id="tooltip-top"
                      title="User Entries"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton onClick={() => {
                        history.push(`/admin/entries/${w.userID}`);
                      }}
                        aria-label="Edit"
                        className={classes.tableActionButton}
                      >
                        <Assessment
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <IconButton
                      aria-label="Edit"
                      className={classes.tableActionButton}
                      onClick={() =>
                        history.push(`/admin/users/edit/${w.userID}`)}
                    >
                      <Edit
                        className={
                          classes.tableActionButtonIcon + " " + classes.edit
                        }
                      />
                    </IconButton>

                    <IconButton
                      onClick={() => {
                        if (window.confirm("Are you sure???")) {
                          new Api()
                            .delete(`/api/users/${w.userID}`)
                            .then((w) => w.ok)
                            .then((w) => {
                              if (w) {
                                users.splice(index, 1);
                                setUsers([...users]);
                              }
                            });
                        }
                      }}
                      aria-label="Close"
                      className={classes.tableActionButton}
                    >
                      <Close
                        className={
                          classes.tableActionButtonIcon + " " + classes.close
                        }
                      />
                    </IconButton>
                  </>
                })
                ),
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
