import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import Api from "../../model/Api";
import { useHistory, useParams } from "react-router";
import { validate } from "helper/validator";
import marc from '../../assets/img/faces/marc.jpg';

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


const styles = {
  formControl: {
    margin: 1,
    width: "100%",
  },
  selectEmpty: {
    marginTop: 2,
  },
  avatar: {
    margin: 'auto',
    display: 'block',
    width: '41%',
    maxWidth: 150,
    borderRadius: '50%',
    boxShadow: '1px 1px 14px -3px #333',
    cursor: 'pointer'
  }
};

const useStyles = makeStyles(styles);

export default function EditUser() {
  const classes = useStyles();

  const [form, setForm] = useState({});
  const [userImage, setUserImage] = useState();
  const history = useHistory();
  const { id } = useParams();

  const [memberships, setMemberships] = useState([]);

  const [valid, setValid] = useState({});
  const [showAccountTab, setShowAccountTab] = useState(false);
  const [chargeAmount, setChargeAmount] = useState(0);
  const [description, setDescription] = useState('');
  const [userBalance, setUserBalance] = useState(0);

  const schema = {
    name: { isRequired: true },
    username: { isRequired: true },
    email: { isRequired: true },
    password: { isRequired: true }
  };


  useEffect(() => {
    new Api().get(`/api/users/${id}`).then(a => a.json()).then(a => {
      a.birthday = new Date(a.birthday).toLocaleDateString();
      a.gender = a.gender ?? true;
      setForm(a);
      getUserBalance();
    });


  }, []);

  function getUserBalance() {
    new Api().get('/api/payments/userbalance/' + id).then(b => b.json()).then(b => {
      setUserBalance(b.balance ?? 0);
    });
  }

  useEffect(() => {
    new Api().get('/api/memberships').then(a => a.json()).then(a => {
      setMemberships(a);
    });
  }, [])

  return (

    <GridContainer>
      <GridItem md={12} sm={12} xs={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className="cardTitleWhite">Edit User</h4>
            <p className="cardCategoryWhite">edit {form.name} </p>
          </CardHeader>
          <CardBody>
            {
              showAccountTab == true ?
                <GridContainer>
                  <GridItem md={6} sm={6}>
                    {
                      (memberships ?? []).map((membership) => (<div>
                        <div className="membership-box" onClick={() => {
                          if (window.confirm(`do you want to add ${membership.days} Days to ${form.name} account expire ?`)) {
                            new Api().get(`/api/users/addmembership/${id}/${membership.membershipID}`).then(a => a.json()).then(a => {
                              if (a == true) {
                                setShowAccountTab(false);
                              }
                              else {
                                alert('user have not enough credit to charge account.');
                              }
                            });
                          }
                        }}>
                          <h4>{membership.title}</h4>
                          <div>
                            <span>{membership.days} Day</span>
                            <span>{membership.price} $</span>
                            <span>{membership.off}%</span>
                          </div>
                        </div>

                      </div>))
                    }
                  </GridItem>

                  <GridItem md={6} sm={6}>

                    <p>
                      Current Credit : {userBalance ?? 0}$
                    </p>
                    <TextField
                      label="Credit"
                      id="credit"
                      type="number"
                      value={chargeAmount}
                      onChange={(e) => {
                        setChargeAmount(e.target.valueAsNumber);
                      }}
                      fullWidth
                    />


<br />
                    <TextField
                      label="Description"
                      id="description"
                      type="text"
                      multiline
                      rows={3}
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      fullWidth
                    />

                    <div className="charge-btn-div">

                      <Button color="primary" onClick={() => {
                        if (chargeAmount < 5) {
                          alert('minimum of charge amount is 5$');
                          return;
                        }

                        new Api().post('/api/payments', {
                          body: JSON.stringify({
                            inc: chargeAmount,
                            userID: id,
                            dec: 0,
                            description: description
                          })
                        }).then(a => a.json()).then(a => {
                          if (a.paymentID > 0) {
                            getUserBalance();
                            alert('credit added to user');
                          }
                        });

                      }}>Add Credit</Button>



                    </div>


                  </GridItem>
                </GridContainer> :

                <GridContainer>
                  <GridItem md={12}>
                    <input accept="images/*"
                      type="file" id="user-avatar" onChange={(e) => {
                        setForm({ file: e.target.files[0], ...form });
                        setUserImage(URL.createObjectURL(e.target.files[0]));
                      }} hidden />
                    <label for="user-avatar">
                      <img className={classes.avatar} src={userImage ?? `images/user/${id}.jpg`} onError={(e) => e.target.src = marc} />
                    </label>
                  </GridItem>

                  {
                    form.userID &&
                    <>
                      <GridItem xs={12} sm={6} md={4}>
                        <TextField
                          label="Name"
                          id="name"
                          value={form.name}
                          onChange={(e) => {
                            setForm({ ...form, name: e.target.value });
                          }}
                          fullWidth


                        />
                        <label className="val-error">
                          {valid.name}
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        <TextField
                          label="Username"
                          id="username"
                          value={form.username}
                          onChange={(e) => {
                            console.log("ewqwe", form);
                            setForm({ ...form, username: e.target.value });
                          }}
                          fullWidth

                        />
                        <label className="val-error">
                          {valid.username}
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        <TextField
                          label="Phone"
                          id="phone"
                          value={form.phone}
                          onChange={(e) => {
                            setForm({ ...form, phone: e.target.value });
                          }}
                          fullWidth

                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        <TextField
                          label="Email"
                          id="email"
                          value={form.email}
                          onChange={(e) => {
                            setForm({ ...form, email: e.target.value });
                          }}
                          fullWidth

                        />
                        <label className="val-error">
                          {valid.email}
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        <TextField
                          label="Birthday"
                          id="birthday"
                          value={form.birthday}
                          onChange={(e) => {
                            setForm({ ...form, birthday: e.target.value });
                          }}
                          fullWidth

                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        <TextField
                          label="Password"
                          id="password"
                          type="password"
                          value={form.password}
                          onChange={(e) => {
                            setForm({ ...form, password: e.target.value });
                          }}
                          fullWidth

                        />
                        <label className="val-error">
                          {valid.password}
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <TextField
                          label="Address"
                          id="address"
                          value={form.address}
                          onChange={(e) => {
                            setForm({ ...form, address: e.target.value });
                          }}
                          fullWidth

                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={3}>
                        <TextField
                          label="Zip Code"
                          id="zipcode"
                          value={form.zipcode}
                          onChange={(e) => {
                            setForm({ ...form, zipcCode: e.target.value });
                          }}
                          fullWidth
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={3}>
                        <FormControl className={classes.formControl}>
                          <InputLabel>Gender</InputLabel>
                          <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={form.gender}
                            onChange={(e) => {
                              setForm({ ...form, gender: e.target.value });
                            }}
                          >
                            <MenuItem value={true}>male</MenuItem>
                            <MenuItem value={false}>female</MenuItem>

                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <TextField
                          label="Emergency Phone"
                          id="emergencyNumber"
                          value={form.emergencyNumber}
                          onChange={(e) => {
                            setForm({ ...form, emergencyNumber: e.target.value });
                          }}
                          fullWidth
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <TextField
                          label="Emergency Contact"
                          id="emergencyContact"
                          value={form.emergencyContact}
                          onChange={(e) => {
                            setForm({ ...form, emergencyContact: e.target.value });
                          }}
                          fullWidth
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        {
                          form.userID &&

                          <TextField
                            label="Account Expire"
                            id="accountExpire"
                            value={(new Date(form.accountExpire)?.toISOString() ?? " T").split('T')[0]}
                            type="date"
                            onChange={(e) => {
                              setForm({ ...form, accountExpire: e.target.value });
                            }}
                            fullWidth
                          />
                        }
                      </GridItem>
                    </>
                  }
                </GridContainer>
            }
          </CardBody>
          <CardFooter>
            {
              showAccountTab == true ?
                <Button color="primary" onClick={() => {
                  setShowAccountTab(false);
                }}>Back</Button> :

                <>

                  <Button color="primary" onClick={() => {
                    const res = validate({ model: schema, formData: form });
                    setValid(res);
                    if (res.isValid == true) {
                      form.registerDate = new Date();
                      new Api().putWithFile(`/api/users/${id}`, form).then(a => a.json()).then(a => {
                        if (a == true) {
                          history.goBack();
                        }
                      });
                    }
                  }}>Save</Button>

                  <Button color="primary" onClick={() => {
                    setShowAccountTab(true);
                  }}>Account</Button>
                </>
            }
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer >


  );
}

