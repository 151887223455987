import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import {  useParams } from "react-router";
import Api from "../../model/Api";
import './viewchats.css';
import sidebar from '../../assets/img/sidebar-dark.jpg';

export default function ViewChats() {
  const [ticket, setTicket] = useState([]);
  const { userID,doctorID } = useParams();

  useEffect(() => {
    new Api()
      .get(`/api/tickets/userchats/${userID}/${doctorID}`)
      .then((w) => w.json())
      .then((w) => {
        setTicket(w);
      })
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className="cardTitleWhite">
              <span>View Chats</span>
                          </h4>
            <p className="cardCategoryWhite"> {`Chats of dr ${ticket.Doctor?.name}  and  ${ticket.User?.name} (${ticket.User?.username})`}</p>
          </CardHeader>
          <CardBody>
            {ticket.chats &&
              <div className="s-chatbox" style={{backgroundImage:`url(${sidebar})`}}>
                <div className="s-chatwall">
                  {
                    ticket.chats.map((a) => <div className={`s-bubble ${a.isDoctor ? 's-p1' : 's-p2'}`}>
                      <div dangerouslySetInnerHTML={{__html : a.text.replaceAll(/\n/g,'<br />')}} />
                      <span>
                        {new Date(a.date).toLocaleString()}
                      </span>
                    </div>)
                  }
                </div>
              </div>
            }
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
