import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import {
  Button,
  Divider,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Add, Close, Edit, Assessment } from "@material-ui/icons";
import Api from "../../model/Api";
import { useHistory, useParams } from "react-router-dom";


const useStyles = makeStyles(taskStyles);

export default function EntryHistory() {

  const { userID } = useParams();
  const classes = useStyles();
  const [entry, setEntry] = useState({});

  function getLocker() {
    new Api()
      .get("/api/entries/userentries/" + userID)
      .then((w) => w.json())
      .then((w) => {
        setEntry(w);
  });
}
useEffect(() => {
  getLocker();
}, []);

return (
  <GridContainer>
    <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader color="primary">
          <h4 className="cardTitleWhite">
            <span>Entries History of {entry.name}</span>
            
          </h4>
          <p className="cardCategoryWhite">Entries</p>
        </CardHeader>
        <CardBody>
          {
            entry &&
            <Table
              tableHeaderColor="primary"
              tableHead={["enterTime", "ExitTime"]}
              tableData={[
                ...(entry.entries ?? []).map((w, index) => ({
                  enter: <span>{new Date(w.enterTime).toLocaleString('en',{
                    year : 'numeric',
                    day : '2-digit',
                    weekday : 'long',
                    month : 'long',
                    hour : '2-digit',
                    minute : '2-digit',
                    hour12 : false
                  })}</span> ,
                  exit: <span>{new Date(w.exitTime).toLocaleString('en',{
                    year : 'numeric',
                    day : '2-digit',
                    weekday : 'long',
                    month : 'long',
                    hour : '2-digit',
                    minute : '2-digit',
                    hour12 : false
                  })}</span>
                }))
              ]}
            />}
        </CardBody>
      </Card>
    </GridItem>
  </GridContainer>
);
}
