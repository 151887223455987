


/// sample code : 
//                          const [valid, setValid] = useState({});
            
//                          const schema = { 
//                            title: { isRequired: true },
//                            writer : { isRequired: true }
//                           };
            
//display error                           
//                           <label className="val-error">
//                           {valid.title}
//                           </label>


//                          const res = validate({ model: schema, formData: form });
//                          setValid(res);
//                          if (res.isValid == true) {
//                          }


///{title : {isRequired:true,maxLength:10,alias : 'My Title'}}
// formData : {title : 'sss'}
export function validate({ model,formData }) {
    let validateResult = {isValid : true};
    Object.entries(model).forEach((a) => {
        const [key,val] = a;
        if((val.isRequired ?? false) == true) {
            if(!formData[key] || formData[key].length == 0) {
                validateResult[key] = `${val?.alias ?? key} is required`;
                validateResult.isValid = false;
            }
        }
        //  else if((val.isRequired ?? false) == true) {
        //     if(!val.value || val.value.length == 0) {
        //         validateResult[key] = `${key} is required`;
        //         validateResult.isValid = false;
        //     }
        // }
    });
    return validateResult;
    
}