import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {
  IconButton,
} from "@material-ui/core";
import {ChatBubble } from "@material-ui/icons";
import { useHistory, useParams } from "react-router";
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import Api from "../../model/Api";

const useStyles = makeStyles(taskStyles);

export default function DoctorTickets() {
  const [tickets, setTickets] = useState([]);
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    new Api()
      .get("/api/doctors/tickets/" + id)
      .then((w) => w.json())
      .then((w) => {
        setTickets(w);
      })
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className="cardTitleWhite">
              <span>Tickets</span>{" "}
            </h4>
            <p className="cardCategoryWhite">Tickets</p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["Title", "Username", "Name","Date", "#"]}
              tableData={[
                ...tickets.map((w, index) => [
                  w.title ?? "",
                  w.User?.username,
                  w.User?.name,
                  w?.date,
                  <>
                    <IconButton
                      onClick={() => {
                        history.push(`/admin/doctors/tickets/view/${w.userID}/${id}`)
                      }}
                      aria-label="chat"
                      className={classes.tableActionButton}
                    >
                      <ChatBubble
                        className={
                          classes.tableActionButtonIcon + " " + classes.edit
                        }
                      />
                    </IconButton>
                  </>,
                ]),
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
