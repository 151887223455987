import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Api from '../../model/Api';
import selectImage from '../../assets/img/selectImage.jpg';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useHistory } from "react-router";
import { validate } from "helper/validator";

const styles = {
  formControl: {
    margin: 1,
    width: "100%",
    marginTop: 25,
  },
  selectEmpty: {
    marginTop: 2,
  },
};

const useStyles = makeStyles(styles);

export default function CreateProduct() {

  const [categories, setCategories] = useState([]);
  const history = useHistory();
  const classes = useStyles();
  const [form, setForm] = useState({});
  const [productImage, setProductImage] = useState();


  const [valid, setValid] = useState({});
  const schema = { 
    title: { isRequired: true },
   };



  function getCategories() {
    new Api().get('/api/categories').then((a) => a.json()).then((a) => {
      form.categoryID = a[0].categoryID;
      setCategories(a);
    });
  }

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4 className="cardTitleWhite">Create Product</h4>
          <p className="cardCategoryWhite">Create new product</p>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem md={4}>
              <label htmlFor="imageUpload">
                <img src={productImage ?? selectImage} style={{ width: '100%' }} />
              </label>
              <input type="file" hidden id="imageUpload" onChange={(e) => {
                setForm({ file: e.target.files[0], ...form });
                setProductImage(URL.createObjectURL(e.target.files[0]));
              }} />
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <TextField
                label="Title"
                value={form.title}
                id="title"
                fullWidth={true}
                onChange={(w) => {
                  setForm({ ...form, title: w.target.value });
                }}
              />

<label className="val-error">
   {valid.title}
  </label>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Category</InputLabel>
                    
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={form.categoryID}
                        onChange={(w) => {
                          setForm({ ...form, categoryID: w.target.value });
                        }}
                      >
                        {
                          categories.map((a) =>
                            <MenuItem value={a.categoryID} selected={a.categoryID == form.categoryID}>{a.name}</MenuItem>
                          )
                        }
                      </Select>
                    
                  </FormControl>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <FormControl
                    className={classes.formControl}
                    style={{ marginTop: 27 }}
                  >
                    <TextField
                    label="Exists Count"
                    id="existsCount"
                    type="number"
                    value={form.existsCount ?? 0}
                    fullWidth
                    onChange={(w) => {
                      setForm({ ...form, existsCount: w.target.valueAsNumber });
                    }}

                  />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    label="short description"
                    id="shortDescription"
                    fullWidth
                    onChange={(w) => {
                      setForm({ ...form, shortDescription: w.target.value });
                    }}

                  />
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem md={3}>
              <TextField label="price" id="price" fullWidth onChange={(w) => {
                setForm({ ...form, price: w.target.value });
              }} />
            </GridItem>

            <GridItem md={3}>
              <Tooltip title="if product has percent off" placement="top">
                <TextField
                  label="percent off (0-100%)"
                  id="percentOff"
                  fullWidth
                  onChange={(e) => {
                    setForm({ percentOff: e.target.value, ...form });
                  }}
                />
              </Tooltip>
            </GridItem>

            <GridItem md={3}>
              <Tooltip title="if product has price off" placement="top">
                <TextField label="price off" id="priceOff" fullWidth onChange={(e) => {
                  setForm({ priceOff: e.target.value, ...form });
                }} />
              </Tooltip>
            </GridItem>
          </GridContainer>
          <br />

          <CKEditor
            config={{
              ckfinder: {
                uploadUrl: "/api/ckeditor",
              },
            }}
            editor={ClassicEditor}
            //data="xxxx"
            onInit={(editor) => {
              // console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setForm({ ...form, description: data });
            }}
            onBlur={(event, editor) => {
              // console.log("Blur.", editor);
            }}
            onFocus={(event, editor) => {
              // console.log("Focus.", editor);
            }}
          />
        </CardBody>
        <CardFooter>
          <Button color="primary" onClick={() => {
               const res = validate({ model: schema, formData: form });
               setValid(res);
            if (res.isValid == true) {
              new Api().postWithFile('/api/products', { ...form, date: new Date().toJSON() }).then(a => a.json()).then(a => {
                if (a.productID) {
                  history.goBack();
                }
              });
            }
          }}>Save</Button>
        </CardFooter>
      </Card>
    </div >
  );
}
