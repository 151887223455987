import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Api from '../../model/Api';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import selectImage from '../../assets/img/selectImage.jpg';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useHistory, useParams } from "react-router";
import { validate } from "helper/validator";

const styles = {
  formControl: {
    margin: 1,
    width: "100%",
    marginTop: 25,
  },
  selectEmpty: {
    marginTop: 2,
  },
};

const useStyles = makeStyles(styles);

export default function EditProduct() {

  const [categories, setCategories] = useState([]);
  const classes = useStyles();
  const [form, setForm] = useState({ priceOff: 0.0, percentOff: 0.0 });
  const [productImage, setProductImage] = useState();
  const history = useHistory();
  const { id } = useParams();

  const [valid, setValid] = useState({});
  const schema = {
    title: { isRequired: true },
  };

  function getCategories() {
    new Api().get('/api/categories').then((a) => a.json()).then((a) => {
      setCategories(a);
      getProduct();
    });
  }
  function getProduct() {
    new Api().get(`/api/products/${id}`).then(a => a.json()).then(a => {
      console.log("www", a);
      setForm(a);
    });
  }

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4 className="cardTitleWhite">Create Product</h4>
          <p className="cardCategoryWhite">Create new product</p>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem md={4}>
              <label htmlFor="imageUpload">
                <img src={productImage ?? `/images/product/${id}.jpg`} style={{ width: '100%' }} onError={(e) => e.target.src = selectImage} />
              </label>
              <input type="file" hidden id="imageUpload" onChange={(e) => {
                setForm({ ...form, file: e.target.files[0] });
                setProductImage(URL.createObjectURL(e.target.files[0]));
              }} />
            </GridItem>
            {
              form.productID &&
              <>
                <GridItem xs={12} sm={12} md={8}>
                  <TextField
                    value={form.title}
                    label="Title"
                    id="title"
                    fullWidth={true}
                    onChange={(w) => {
                      setForm({ ...form, title: w.target.value });
                    }}
                  />
                  <label className="val-error">
                    {valid.title}
                  </label>
                  <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                      <FormControl className={classes.formControl}>
                        <InputLabel>Category</InputLabel>
                        <Select
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={form.categoryID}
                          onChange={(w) => {
                            setForm({ ...form, categoryID: w.target.value });
                          }}
                        >
                          {
                            categories.map((a) =>
                              <MenuItem value={a.categoryID}>{a.name}</MenuItem>
                            )
                          }
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <FormControl
                        className={classes.formControl}
                        style={{ marginTop: 27 }}
                      >
                  <TextField
                    label="Exists Count"
                    id="existsCount"
                    type="number"
                    value={form.existsCount ?? 0}
                    fullWidth
                    onChange={(w) => {
                      setForm({ ...form, existsCount: w.target.valueAsNumber });
                    }}
                  />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        label="short description"
                        id="shortDescription"
                        fullWidth
                        value={form.shortDescription}
                        onChange={(w) => {
                          setForm({ ...form, shortDescription: w.target.value });
                        }}

                      />
                    </GridItem>

                  </GridContainer>
                </GridItem>

                <GridItem md={3}>
                  <TextField label="price" value={form.price} type="number" min={0} id="price" fullWidth onChange={(w) => {
                    setForm({ ...form, price: w.target.value });
                  }} />
                </GridItem>

                <GridItem md={3}>
                  <Tooltip title="if product has percent off" placement="top">
                    <TextField
                      type="number"
                      label="percent off (0-100%)"
                      id="percentOff"
                      min={0}
                      max={100}
                      fullWidth
                      value={form.percentOff}
                      onChange={(e) => {
                        setForm({ ...form, percentOff: e.target.value });
                      }}
                    />
                  </Tooltip>
                </GridItem>

                <GridItem md={3}>
                  <Tooltip title="if product has price off" placement="top">
                    <TextField label="price off" id="priceOff" type="number" min={0} value={form.priceOff} fullWidth onChange={(e) => {
                      setForm({ ...form, priceOff: e.target.value });
                    }} />
                  </Tooltip>
                </GridItem>
              </>
            }
          </GridContainer>
          <br />

          {
            form.productID &&


            <CKEditor
              config={{
                ckfinder: {
                  uploadUrl: "/api/ckeditor",
                },
              }}
              editor={ClassicEditor}
              data={form.description}
              //data="xxxx"
              onInit={(editor) => {
                // console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setForm({ ...form, description: data });
              }} a
              onBlur={(event, editor) => {
                // console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                // console.log("Focus.", editor);
              }}
            />
          }
        </CardBody>
        <CardFooter>
          <Button color="primary" onClick={() => {
            const res = validate({ model: schema, formData: form });
            setValid(res);
            if (res.isValid == true) {
              new Api().putWithFile(`/api/products/${id}`, form).then(a => a.json()).then(a => {
                if (a == true) {
                  history.goBack();
                }
              });
            }
          }}>Save</Button>
        </CardFooter>
      </Card>
    </div >
  );
}
