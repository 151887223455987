import React, { useState } from 'react';
import './signin.css'
import bg from '../../assets/img/sidebar-dark.jpg';
import { Button, TextField } from '@material-ui/core';
import Api from '../../model/Api';
import AuthService from '../../model/AuthService';
import { Redirect} from 'react-router';

export default function SigninScreen() {

    const [form, setForm] = useState({});
    const isLogin = new AuthService().getToken();

    function login() {
        new Api().post('/api/admins/login', { body: JSON.stringify(form) }).then(a => a.json()).then(a => {
            if (a.status == "success") {
                new AuthService().setToken(a.token);
                window.location.reload();
            }
            else {
                setForm({ ...form, error: 'Username or password is wrong!' });
            }
        });
    }


    if(isLogin) {
        return <Redirect to="/admin/dashboard" />
    }

    return (
        <div className="login-hs-v-1">
            <div className="limiter">
                <div className="container-login100" style={{ backgroundImage: `url(${bg})` }}>
                    <div className="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
                        <form className="login100-form validate-form" action="/login" method="post">
                            <span className="login100-form-title p-b-49">
                                Login
                    </span>

                            <div className="wrap-input100">
                                <TextField label="Username" fullWidth onChange={(e) => {
                                    setForm({ ...form, username: e.target.value, error: '' });
                                }} value={form.username} />
                            </div>

                            <div className="wrap-input100" >
                                <TextField label="Password" type="password" fullWidth onChange={(e) => {
                                    setForm({ ...form, password: e.target.value, error: '' });
                                }} value={form.password} />
                            </div>
                            <div className="container-login100-form-btn">
                                <div className="wrap-login100-form-btn">
                                    <div className="login100-form-bgbtn"></div>
                                    <Button className="login100-form-btn" onClick={login}>
                                        Login
                                    </Button>
                                </div>
                            </div>

                            {form.error &&
                                <div className="hs-error">
                                    {form.error}
                                </div>
                            }

                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}