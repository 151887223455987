
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import DashboardPage from "views/Dashboard/Dashboard";
import Categories from "views/Categories/Categories";
import NotificationsPage from "views/Notifications/Notifications";
import BlogList from "views/Blog/BlogList";
import CreateBlog from "views/Blog/CreateBlog";
import SettingsPage from "views/Setting/Setting";
import CreateProduct from "views/Product/CreateProduct";
import ProductList from "views/Product/ProductList";
import CreateDoctor from "views/Doctor/CreateDoctor";
import DoctorList from "views/Doctor/DoctorList";
import Lockers from "views/Locker/lockers";
import CreateUser from "views/User/CreateUser";
import UserList from "views/User/UserList";
import Notifications from "@material-ui/icons/Notifications";
import { Accessibility, AllInbox, Assignment, MarkunreadMailbox,ShoppingCart,Receipt, Settings } from "@material-ui/icons";
import TicketsList from "views/Tickets/TicketsList";
import BlogEdit from "views/Blog/BlogEdit";
import EditProduct from "views/Product/EditProduct";
import EditDoctor from "views/Doctor/EditDoctor";
import EditUser from "views/User/EditUser";
import DoctorTickets from "views/Doctor/DoctorTickets";
import ViewChats from "views/Doctor/ViewChats";
import Profile from "views/Profile/Proile";
import LockerHistory from "views/Locker/LockerHistory";
import OrderList from "views/Order/OrderList";
import PaymentList from "views/Payment/PaymentList";
import EntryHistory from "views/User/EntryHistory";
import CreateOrder from "views/Order/CreateOrder";
import Memberships from "views/Membership/Membership";

const dashboardRoutes = [

  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/profile",
    name: "Profile",
    icon: Assignment,
    customRoute: true,
    component: Profile,
    layout: "/admin"
  },
  {
    path: "/blogs/create",
    name: "Create Blog",
    icon: Assignment,
    customRoute: true,
    component: CreateBlog,
    layout: "/admin"
  },
  {
    path: "/blogs/edit/:id",
    name: "Edit Blog",
    icon: Assignment,
    customRoute: true,
    component: BlogEdit,
    layout: "/admin"
  },
  {
    path: "/blogs",
    name: "blogs",
    icon: Assignment,
    component: BlogList,
    layout: "/admin"
  },

  {
    path: "/products/create",
    name: "Create Product",
    icon: MarkunreadMailbox,
    customRoute: true,
    component: CreateProduct,
    layout: "/admin"
  },
  {
    path: "/products/edit/:id",
    name: "Edit Product",
    icon: MarkunreadMailbox,
    customRoute: true,
    component: EditProduct,
    layout: "/admin"
  },
  {
    path: "/products",
    name: "Products",
    icon: MarkunreadMailbox,
    component: ProductList,
    layout: "/admin"
  },
  {
    path: "/categories",
    name: "Categories",
    icon: Dashboard,
    component: Categories,
    layout: "/admin"
  },

  {
    path: "/entries/:userID",
    name: "",
    customRoute : true,
    component: EntryHistory,
    layout: "/admin"
  },

  {
    path: "/doctors/create",
    name: "Create doctor",
    icon: Accessibility,
    customRoute: true,
    component: CreateDoctor,
    layout: "/admin"
  },
  {
    path: "/doctors/edit/:id/:mode?",
    name: "Edit doctor",
    icon: Accessibility,
    customRoute: true,
    component: EditDoctor,
    layout: "/admin"
  },
  {
    path: "/doctors/tickets/view/:userID/:doctorID",
    name: "tickets",
    icon: Accessibility,
    customRoute: true,
    component: ViewChats,
    layout: "/admin"
  },
  {
    path: "/doctors/tickets/:id",
    name: "tickets",
    icon: Accessibility,
    customRoute: true,
    component: DoctorTickets,
    layout: "/admin"
  },

  {
    path: "/doctors",
    name: "Doctors / Coaches",
    icon: Accessibility,
    component: DoctorList,
    layout: "/admin"
  },

  {
    path: "/orders/create",
    customRoute : true,
    component: CreateOrder,
    layout: "/admin"
  },

  {
    path: "/orders",
    name: "Orders",
    icon: ShoppingCart,
    component: OrderList,
    layout: "/admin"
  },

  {
    path: "/payments",
    name: "Payments",
    icon: Receipt,
    component: PaymentList,
    layout: "/admin"
  },

  {
    path: "/tickets/:type/:userID",
    name: " ",
    customRoute: true,
    component: TicketsList,
    layout: "/admin"
  },

  {
    path: "/lockers/:lockerID",
    name: "Lockers",
    customRoute: true,
    component: LockerHistory,
    layout: "/admin"
  },

  {
    path: "/lockers",
    name: "Lockers",
    icon: AllInbox,
    component: Lockers,
    layout: "/admin"
  },
  {
    path: "/users/create",
    name: "Create user",
    icon: Person,
    customRoute: true,
    component: CreateUser,
    layout: "/admin"
  },
  {
    path: "/users/edit/:id",
    name: "Create user",
    icon: Person,
    customRoute: true,
    component: EditUser,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Users",
    icon: Person,
    component: UserList,
    layout: "/admin"
  },
  {
    path: "/memberships",
    name: "Memberships",
    icon: Dashboard,
    component: Memberships,
    layout: "/admin"
  },
  {
    path: "/settings",
    name: "Settings",
    icon: Settings,
    component: SettingsPage,
    layout: "/admin"
  },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: "/admin"
  // },
];

export default dashboardRoutes;
