import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import Table from "components/Table/Table.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Api from '../../model/Api';
import selectImage from '../../assets/img/selectImage.jpg';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  IconButton,
  Radio,
  RadioGroup
} from "@material-ui/core";
import { useHistory } from "react-router";
import { validate } from "helper/validator";
import { Autocomplete } from "@material-ui/lab";
import { Close } from "@material-ui/icons";

const styles = {
  formControl: {
    margin: 1,
    width: "100%",
    marginTop: 25,
  },
  selectEmpty: {
    marginTop: 2,
  },
};

const useStyles = makeStyles(styles);

export default function CreateOrder() {

  const [products, setProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const history = useHistory();
  const classes = useStyles();
  const [orderDetail, setOrderDetail] = useState({ count: 1 });
  const [form, setForm] = useState({ details: [], state: 'sent', description: 'order created by admin',paymentType : "credit" });
  const [refresh, setRefresh] = useState(false);

  const [valid, setValid] = useState({});
  const [detailValid, setDetailValid] = useState({});
  const schema = {
    userID: { isRequired: true, alias: 'Username' },
  };

  const detailSchema = {
    productID: { isRequired: true, alias: 'Product' },
    count: { isRequired: true },
  };



  function getProducts() {
    new Api().get('/api/products').then((a) => a.json()).then((a) => {
      setProducts(a);
    });
  }

  function getUsers() {
    new Api().get('/api/users').then((a) => a.json()).then((a) => {
      setUsers(a);
    });
  }

  useEffect(() => {
    getProducts();
    getUsers();
  }, []);


  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4 className="cardTitleWhite">Create Order</h4>
          <p className="cardCategoryWhite">Create new order</p>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={6} sm={6} md={4}>
              <FormControl>
                <Autocomplete
                  id="target-user"
                  options={[...users.map(a => a)]}
                  getOptionLabel={(option) => option.username}
                  style={{ width: 250 }}
                  renderInput={(params) => <TextField {...params}
                    value={form?.userID}
                    label="Select User" className="form-control" />}
                  onChange={(e, item) => {
                    setForm({ ...form, userID: item?.userID });
                  }}
                />
              </FormControl>
              <label className="val-error" >
                {valid.userID}
              </label>

            </GridItem>
          </GridContainer>
          <div className="p-line"></div>
          <GridContainer>
            <GridItem md={4} sm={4} xs={4}>
              <Autocomplete
                id="target-product"
                options={[...products.map(a => a)]}
                getOptionLabel={(option) => option.title}
                style={{ width: '100%' }}
                renderInput={(params) => <TextField {...params}
                  // value={form?.product}
                  label="Select Product" className="form-control" />}
                onChange={(e, item) => {
                  const product = products.find(a => a.productID == item?.productID);
                  setOrderDetail({ ...orderDetail, price: product.price, productID: item?.productID, product: { title: product?.title } });
                }}
              />
              <label className="val-error">
                {detailValid.productID}
              </label>
            </GridItem>
            <GridItem md={2} sm={4} xs={4}>
              <FormControl style={{ marginTop: 15 }}>
                <TextField type="number" min={1} max={1000} fullWidth placeholder="Count" value={orderDetail.count} onChange={(e) => {
                  if (e.target.value) {
                    setOrderDetail({ ...orderDetail, count: e.target.valueAsNumber });
                  }
                }} />
              </FormControl>
              <label className="val-error">
                {detailValid.count}
              </label>
            </GridItem>

            <GridItem md={2} sm={4} xs={4}>
              <FormControl style={{ marginTop: 15 }}>
                <TextField type="text" placeholder="Price" fullWidth disabled value={orderDetail?.price ?? 0} />
              </FormControl>

            </GridItem>

            <GridItem md={2} sm={4} xs={4}>
              <Button style={{ marginTop: 15 }} color="primary" onClick={() => {
                const res = validate({ model: detailSchema, formData: orderDetail });
                setDetailValid(res);
                if (res.isValid == true) {
                  setForm((b) => {
                    b.details.push({ ...orderDetail });
                    return b;
                  });
                  setOrderDetail({ count: 1 });
                }
              }}>Add</Button>
            </GridItem>
          </GridContainer>

          <div className="p-line"></div>

          <GridContainer>
            <Table
              tableHeaderColor="primary"
              tableHead={["Product", "Count", "Price", "Total", "#"]}
              tableData={[
                ...form.details.map((w, index) => ({
                  product: w?.product?.title,
                  count: w.count,
                  price: `${w.price}$`,
                  total: `${(w.price * w.count) ?? 0}$`,
                  action:
                    <>
                      <IconButton
                        onClick={() => {
                          if (window.confirm("Are you sure to remove this item ?")) {
                            setForm((b) => {
                              b.details.splice(index, 1);
                              return b;
                            });
                            setRefresh(!refresh);
                          }
                        }}
                        aria-label="Close"
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + " " + classes.close
                          }
                        />
                      </IconButton>
                    </>
                }))

              ]}
            />
          </GridContainer>
          <div className="p-line"></div>

          <GridContainer>
            <GridItem md={12} sm={12}>
            <TextField multiline rows={4} placeholder="Description" label="Description" fullWidth value={form.description} onChange={(e) => {
              setForm({...form,description:e.target.value});
            }} />
            </GridItem>
          </GridContainer>

        
        
        </CardBody>
        <CardFooter>

          <div>
Payment type :
          <RadioGroup name="paymentType"  value={form.paymentType}  onChange={(e) => {
            setForm({...form,paymentType : e.target.value});
          }}>
            <FormControlLabel value="credit" control={<Radio />} label="User Credit" />
            <FormControlLabel value="cash" control={<Radio />} label="Cash" />
            </RadioGroup> 
            </div>
          <Button color="primary" onClick={() => {
            const res = validate({ model: schema, formData: form });
            setValid(res);
            if (res.isValid == true) {
              new Api().post('/api/orders', { body: JSON.stringify({ ...form, date: new Date().toJSON() }) }).then(a => a.json()).then(a => {
                if (a.orderID) {
                  history.goBack();
                }
              });
            }
          }}>Save</Button>
          <div style={{width : 120}}></div>
        </CardFooter>
      </Card>
    </div>
  );
}
